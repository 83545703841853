const config = {
    version: '24.11.27:1',
    homePath: "/",
    // homePath: "http://localhost:80/",
    proxyPath: "http://localhost:6030/",
    locale: "ru",
    margin: 1,
    headerHeight: 50,
    innerHeight: 20,
    footerHeight: 10,
    topHeight: 30,
    leftWidth: 180,
    messageTimeout: 500,
    dialogBodyPadding: 0,
    relationPageSize: 50,
    devMode: true,
    labelMode: true,
    map: {fontSize: '12px'},
    ui: 'normal raised round',
   homeCenter: [5600000, 7400000],
   homeZoom: 8,
    topos: ['osm','eeco', 'esri','biotopsmap'],
    useApplyButton: true
};
window.IasConfig = config;
