import React from 'react';
import {Button, Panel} from "@sencha/ext-react-modern";
import BaseTopPage from "../Base/BaseTopPage";

const Ext = window['Ext'];

const keyName = "Header";

class Header extends BaseTopPage {

    render() {
        const context = this;
        const curPage = window.location.search.match(/page=[\D\d]*/)[0].substr(5);
        const logo = <Button
            key={"logo"}
            cls={"logo-lo"}
            handler={() => context.props.appViewPort.rePage('Index')}
            text={context.props.globalContext?.header}/>;
        const p = context.props.pageName.toLowerCase();
        const contacts = <Panel layout={'hbox'} cls={"admin-contacts"} bodyCls={'admin-body'}>
            <p><span className={'link'} onClick={() => {
                const msg = Ext.Msg.alert(`Администратор ${context.props.globalContext?.name}`, '');
                msg.setHtml(`<div class="popup-inner">` +
                    `<p><span class="${"x-fa fa-id-card"}"></span> ФИО: ${context.props.globalContext?.adm_name}</p>\n` +
                    `<p><span class="${"x-fa fa-envelope"}"></span> E-mail: ${context.props.globalContext?.adm_email}</p>\n` +
                    `<p><span class="${"x-fa fa-phone"}"></span> Телефон: ${context.props.globalContext?.adm_phone}</p>` +
                    `</div>`
                )
            }}>администратор</span></p>
            {/*<p><span className={'link'} onClick={() => {
                const msg = Ext.Msg.alert('Основные реквизиты', '');
                msg.setHtml(`<div class="popup-inner">` +
                    `<p><span class="${'x-fa fa-id-card'}"></span> Наименование: ${context.props.globalContext?.rek_name}</p>\n` +
                    `<p><span class="${'x-fa fa-address-book'}"></span> Адрес: ${context.props.globalContext?.rek_address}</p>\n` +
                    `<p><span class="${"x-fa fa-phone"}"></span> Телефон: ${context.props.globalContext?.rek_phone}</p>` +
                    `</div>`
                )
            }}>основные контактные данные</span></p>*/}
            <p><span className={'link'} onClick={() => {
                const type = (p.indexOf('zmu') > -1 ? 'zmu' : ((p.indexOf('admin') > -1) ? 'admin' : 'user'));
                window.open(`${window.IasConfig.homePath}files/manuals/${type}_manual.pdf`)
            }
            }>руководство {p.indexOf('zmu') > -1 ? ' ЗМУ' : ((p.indexOf('admin') > -1) ? ' администратора' : ' пользователя')}</span>
            </p>

        </Panel>;
        return <Panel
            id={keyName}
            cls={'no-print'}
            bodyCls={"x-panelheader"}
            key={keyName}
            docked={"top"}
            layout="hbox" height={window.IasConfig.headerHeight}
            width={window.innerWidth - 2 * window.IasConfig.margin - 14}
            shadow margin={window.IasConfig.margin}>
            {logo}{contacts}
        </Panel>
    }
}

export default Header;