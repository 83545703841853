import React from "react";
import BaseByMapGrid from "../../../Base/BaseByMapGrid";
import * as f from "../../../common/Funcs";

const keyName = 'RoutesGrid';

// export default class RoutesGrid extends BaseZMUGrid {
export default class RoutesGrid extends BaseByMapGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        // context.subElementName = 'DocumentPage';
        context.tableName = `routes`;
        context.paging=true;
         context.pageSize = 3000;
        context.columnsDefaults = [
            {column_name: "route_name", pos: 1, className: 'no-copy', title: true, maxWidth: 100, minWidth: 100},
            {column_name: "region_name", className: 'no-copy', pos: 2, minWidth: 150, maxWidth: 150},
            {
            column_name: "huntuser_name",
            className: 'no-copy',
            flex: {grow: 2},
            pos: 3,
            width: 200
        },
            {
                column_name: "year_on",
                pos: 4, minWidth: 75, maxWidth: 100,column_label:'С...'
            },
            {
                column_name: "year_off",column_label:'По...', minWidth: 75, maxWidth: 100,
                pos: 5
            },
            {
                column_name: "route_active",
                pos: 0
            },
            {"column_name": "geom", className: 'no-copy', ignoreExport: true}
        ]
    }

    componentDidMount() {
        super.componentDidMount();
        // context.getData(context, context.props.parent.activeColumn);
    }

    getHeight() {
        return f.getCentralHeight() - ((!this.paging) ? 40 : 70);
    }
}