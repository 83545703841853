import React from 'react';
import * as f from "../../common/Funcs";
import BaseSubGridPanel from "../../Base/BaseSubGridPanel";
import * as z from "../../common/zmuFuncs";

const Ext = window['Ext'];
const keyName = `EaRoutesSub`;

export default class EaRoutesSub extends BaseSubGridPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.parent = props.parent;
        context.rowId = props.rowId;
        context.tableName = 'earoutes';
        //элемент окна редактирования
        context.idName = 'gid';
        context.gridName = "EaRoutesSubGrid";
        context.subElementName = 'RoutePage';
        context.hiddenColumns = props.hiddenColumns || ['layout_ea', 'gid'];
        let isauth = f.getRoles();
        const readonly=(!isauth.includes(1) && !isauth.includes(15) && !isauth.includes(10)) ;
        context.columnsDefaults = [
            {
                column_name: 'rights',
                hidden: true
            },
            {
                column_name: "pass_count",
                className: 'no-copy',
                data_type: 'counting',
                null: 0,
                readonly,
                pos: 1,
                maxWidth: 50,
                minWidth: 50,
                getColor: (val) => (val > 0) ? [100, 100, 100, 1] : [100, 100, 100, 0.5],
                //функция вызывается при смене числа
                func: (record) => {
                    if (record.data.pass_count > 1 && Number(context.props.record.all_ga) > 50) {
                        f.alert('Повторное прохождение возможно только на территориях до 50 т.га');
                        return false;
                    }
                    if (record.data.ea_name == '' ) return false;
                    const props = context.props;
                    //перерисовать на карте
                    props.parent.olMap.changeFeatures({
                        changes: {id: record.data.gid, pass_count: record.data.pass_count},
                        context: props.parent.olMap,
                        layerName: context.tableName
                    });
                    const params = {
                        data: {routeId: record.data.gid, passCount: record.data.pass_count},
                    };
                    z.setRoutePlan(params);
                    return true;
                }
            },
            {column_name: "route_name", pos: 2, className: 'no-copy', title: true, maxWidth: 90},
            {
                column_name: "is_approved", hidden: false,
                pos: 0
            },
            {
                column_name: "route_active",
                pos: 0, hidden: true
            },
            {column_name: 'fst', pos: 3, maxWidth: 70},
            {column_name: 'fld', pos: 4, maxWidth: 70},
            {column_name: 'bog', pos: 5, maxWidth: 70},
            {column_name: 'all', pos: 6, maxWidth: 70},
        ];
        context.state = {
            data: context.props.data || [], title: 'Started', showEditPanel: false,
            showAll: false, singleMode: false
        };
        context.store = Ext.create('Ext.data.Store', {
            data: context.state.data,
        });
        context.topMenuButtons = [];
        context.noSingle = true;

//меню
        context.topMenuButtonNames = [];
        context.rowId = props.rowId;
        context.state = {
            data: [],
        };
        context.store = Ext.create('Ext.data.Store', {
            data: context.state.data,
        });
        context.topMenuButtons = [
            {
                iconCls: 'x-fa fa-refresh',
                html: `<label class="menu-label">Обновить</label>`,
                tooltip: f.locale('reload'),
                //запрос данных без пересчета тестов
                handler: () => context.grid.getData({context: context.grid})
            }];
        if (context.props.parent.isEditRight())
            context.topMenuButtons.push({
                iconCls: 'x-fa fa-plus',
                html: `<label class="menu-label">Добавить</label>`,
                handler: () => {
                    document.setCookie(context.tableName, null);
                    context.rowId = null;
                    context.showEditPanel(context);
                    const eaFeatures = context.props.parent.olMap.featuresSource.getFeatures();
                    setTimeout(() => {
                        const routeMap = context.editForm.olMap;
                        routeMap.addFeatures({features: eaFeatures, context: routeMap, layerName: 'ea'});
                        routeMap.gotoFeatures({features: eaFeatures, context: routeMap});
                    }, 1000);
                }
            });
    }

    attention(context) {
        context.parent.pnl0.cmp.setHtml('<span class="attention">Изменена маршрутная сеть ИТ. Необходимо выполнить пересчет.</>');
    }

    addMenuButtons() {
    }
}

