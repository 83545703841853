import ErrorBoundary from "../ErrorBoundary";
import React, {Component} from 'react';
import BaseMap from "./BaseMap";
import * as Controls from "../Maps/MapControls";
import zonesStyle from './styles/zonespol'
import landspolStyle from "./styles/landspol";
import admpolStyle from "./styles/admpol";
import dtplinStyle from "./styles/dtplin";
import dtppntStyle from "./styles/dtppnt";
import ooptStyle from "./styles/ooptpol";
import plansetStyle from "./styles/planset";

const keyName = "FullMap";
export default class FullMap extends BaseMap {
    static defaultProps = {
        mapButtonNames: [],
        layerNames: [
            // {layerName: 'zonespol', hidden: true, type: 'own', styleFunction: zonesStyle},
            // {layerName: 'zninspol', hidden: true, type: 'own', styleFunction: zonesStyle},
            {layerName: 'landspol', hidden: false, type: 'own', styleFunction: landspolStyle},
            {layerName: 'admpol', hidden: false, type: 'own', styleFunction: admpolStyle},
            // {layerName: 'dtplin', hidden: true, type: 'own', styleFunction: dtplinStyle},
            // {layerName: 'dtppnt', hidden: true, type: 'own', styleFunction: dtppntStyle}
        ]
    }

    constructor(props) {
        super(props);
    }

    getAdditControls() {
        return [
            Controls.getGeoCoder(),
            Controls.getBasemapToggle(),
            Controls.getLayersToggle(),
            Controls.getAreaMeasure(),
            Controls.getLineMeasure(),
            Controls.getPdfTool(),
            Controls.getGotoPoint(),
            Controls.getInfo(),
            Controls.getClearSelection()];
    }
}
