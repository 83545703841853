import React, {Component} from "react";
import * as f from "../../../common/Funcs";
import * as z from "../../../common/zmuFuncs";
import BaseAccPage from "../../../Base/BaseAccPage";

const Ext = window['Ext'];
const keyName = `ZmuAdminPage`;
const title = `Зимний маршрутный учет. Настройка видов года.`;
export default class ZmuAdminPage extends BaseAccPage {

    constructor(props) {
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        //очистка фильтра при загрузке, иначе путается с мониторингом и реестром
        super(props);
        const context = this;
        context.filters = [{name: 'year'},
          //  {name: 'isbird'}
        ];
        context.gridName = "ZmuAdminGrid";
        // context.tableName = 'acc_table';
        context.idName = 'row_id';
        context.hiddenColumns = [context.idName];
        // context.topMenuButtonNames = ['xls', 'html', 'reload'];
        // context.footerButtonNames = ['save'];
        //меню страницы
    }

    saveFunc (context,callback)  {
        if (context.grid.grid.cmp) {
            const modified = context.grid.grid.cmp.store.getData().items.filter(i => i.data.modified).map(i => i.data);
            if (modified.length > 0) {
                const closeFunc = (result) => {
                    if (result.flash) {
                        f.alert({
                            title: 'Ответ сервера',
                            message: `Изменения не сохранены. Сервер вернул ошибку: ${JSON.stringify(result.flash)}`
                        });
                    } else {
                        context.grid.grid.setState({modified:false});
                        context.grid.grid.cmp.store.getData().items.filter(i => i.data.modified).map(i => i.data.modified = false);
                        if (callback) callback(result);
                        f.toast({
                            title: 'Ответ сервера',
                            message: 'Изменения сохранены.',
                            timeout: 1000
                        });
                    }
                }
                z.setSpecTableData({data: modified, callback: closeFunc})
            }
        }
    }

}